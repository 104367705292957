export enum PaymentNomination {
    GENERAL_LIABILITY_INSURANCE = "GENERAL_LIABILITY_INSURANCE",
    PROFESSIONAL_LIABILITY_INSURANCE = "PROFESSIONAL_LIABILITY_INSURANCE",
    WORKERS_COMPENSATION_INSURANCE = "WORKERS_COMPENSATION_INSURANCE",
    DISABILITY_INSURANCE = "DISABILITY_INSURANCE",
}

export enum PaymentStatus {
    SCHEDULED = "SCHEDULED",
    COMPLETED = "COMPLETED",
    OVERDUE = "OVERDUE",
    CANCELLED = "CANCELLED",
    PENDING_FOR_ACTION = "PENDING_FOR_ACTION",
    PENDING = "PENDING",
    DECLINED = "DECLINED",
}

export enum PaymentMethod {
    CUSTOMER_CARD = "CUSTOMER_CARD",
    CUSTOMER_ACCOUNT = "CUSTOMER_ACCOUNT",
    SUBLIME_CARD = "SUBLIME_CARD",
    SUBLIME_ACCOUNT = "SUBLIME_ACCOUNT",
}
