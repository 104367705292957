import axios from 'axios';
import { PAYMENTS_ENDPOINT } from "@/constants/endpoints";
import QueryUtils from "@/utils/QueryUtils";
import authHeader from "@/services/auth-header";
import {PaymentDTO} from "@/dto/payment/PaymentDTO";
import Page from "@/dto/taxation/Page";
import PaymentFilter from "@/dto/payment/PaymentFilter";
import PaymentMonitoredPayload from "@/dto/payment/PaymentMonitoredPayload";
import UpdatePaymentPayload from "@/dto/payment/UpdatePaymentPayload";

class PaymentsService {

    getPayments(filter: PaymentFilter) {
        const queryString = QueryUtils.buildQueryString(
        ["pageNumber", filter.pageNumber],
        ["pageSize", filter.pageSize],
              ["nomination", filter.nomination],
              ["includeNonMonitored", filter.includeNonMonitored],
              ["status", filter.status],
        )
        return axios.get<Page<PaymentDTO>>(`${PAYMENTS_ENDPOINT}${queryString}`, {headers:authHeader()});
    }

    getPayment(id: number) {
        return axios.get<PaymentDTO>(`${PAYMENTS_ENDPOINT}/${id}`, {headers: authHeader()});
    }

    updatePaymentMonitored(id: number, payload: PaymentMonitoredPayload) {
        return axios.put(`${PAYMENTS_ENDPOINT}/${id}/monitored`, payload, {headers: authHeader()});
    }

    updatePayment(id: number, payload: UpdatePaymentPayload) {
        return axios.put(`${PAYMENTS_ENDPOINT}/${id}`, payload, {headers: authHeader()});
    }

}

export default new PaymentsService();
