



































































































import { Component, Vue } from "vue-property-decorator";
import { processError } from "@/utils/ComponentUtils";
import { namespace } from "vuex-class";
import { PaymentDTO } from "@/dto/payment/PaymentDTO";
import PaymentsService from "@/services/PaymentsService";
import { PaymentMethod, PaymentStatus } from "@/constants/PaymentConstants";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import SelectOption from "@/components/common/SelectOption";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";

const AppModule = namespace("App");

@Component<Payment>({
  components: { PortalSelect, PortalInput, PortalCheckbox },
  computed: {
    PaymentStatus() {
      return PaymentStatus
    },
    SelectOption() {
      return SelectOption
    },
    PaymentMethod() {
      return PaymentMethod
    }
  },
})
export default class Payment extends Vue {

  @AppModule.Action
  private startLoading!: () => void;

  @AppModule.Action
  private stopLoading!: () => void;

  payment: PaymentDTO | null = null;
  private resolveUpdatePayment = true;

  mounted(): void {
    this.loadPayment();
  }

  loadPayment(): void {
    this.startLoading();
    PaymentsService.getPayment(this.id).then(
      ok => {
        this.payment = ok.data;
        if (this.payment?.paymentStatus === PaymentStatus.COMPLETED) {
          this.resolveUpdatePayment = false;
        }
        this.stopLoading();
      },
      err => {
        this.stopLoading();
        processError(err, this);
      }
    )
  }

  editPayment(): void {
    this.$validator.validate('update-payment-form.*').then((isValid) => {
      if (isValid && this.resolveUpdatePayment && this.payment) {
        this.startLoading();
        PaymentsService.updatePayment(this.id, {
          paymentStatus: this.payment.paymentStatus,
          paymentMethod: this.payment.paymentMethod,
          paymentAccount: this.payment.paymentAccount,
        }).then(
          ok => {
            this.loadPayment();
            this.stopLoading();
          },
          err => {
            this.stopLoading();
            processError(err, this);
          }
        )
      }
    })
  }

  switchPaymentMonitored(): void {
    if (this.payment) {
      this.startLoading();
      PaymentsService.updatePaymentMonitored(this.id,{ value: this.payment.monitored }).then(
        ok => {
          this.loadPayment();
          this.stopLoading();
        },
        err => {
          this.stopLoading();
          processError(err, this);
        }
      )
    }
  }

  get id(): number {
    return Number.parseInt(this.$route.params.id);
  }

}
